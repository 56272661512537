/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useContext, useEffect, useState } from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { Popover } from 'react-tiny-popover';
import {
  calculateCustomPriceItem,
  calculateDefaultPricesItem,
  createCartItem,
} from '../../services/cart';
import BoxQuantitySelector from '../boxQuantitySelector';
import BoxSizeSelector from '../boxSizeSelector';
import UploadFile from '../uploadFile';
import './styles.scss';
import Loader from '../loader';
import { Context } from '../../context';
import validateCalculatorInputs from '../../services/validate-calculator';
import getSizesForCategory from '../../services/size';
import getQuantitiesForCategory from '../../services/quantities';

export default function SelectSizeElement() {
  let { categoryId } = useParams();
  const [listQuantities, setListQuantities] = useState([]);
  const [prices, setPrices] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
  const [checkedTwo, setCheckedTwo] = useState(false);
  const [priceActual, setPriceActual] = useState(null);
  const [quantityCustom, setQuantityCustom] = useState(null);
  const [priceCustom, setPriceCustom] = useState(null);
  const [isPopoverWidthOpen, setIsPopoverWidthOpen] = useState(false);
  const [isPopoverHeigthOpen, setIsPopoverHeigthOpen] = useState(false);
  const [popoverWidthMessage, setPopoverWidthMessage] = useState('');
  const [popoverHeightMessage, setPopoverHeightMessage] = useState('');
  const [indexQuantity, setIndexQuantity] = useState(0);
  const [customSizeActive, setCustomSizeActive] = useState(false);
  const [sizes, setSizes] = useState([]);
  const [quantityErrorMessage, setQuantityErrorMessage] = useState('');
  const [size, setSize] = useState({
    id: null,
    width: null,
    height: null,
    custom: false,
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [fileId, setFileId] = useState(null);
  const sessionToken = localStorage.getItem('sessionToken');
  const history = useHistory();
  const { cart, setCart } = useContext(Context);
  const [priceWithoutPromo, setPriceWithoutPromo] = useState(null);
  const location = useLocation();

  const [quantity, setQuantity] = useState(() => {
    const categoryIdInt = parseInt(categoryId, 10);
    return [29, 30, 31, 32, 33].includes(categoryIdInt) ? 1 : 50;
  });

  const handleSizeSelection = (selectedSize) => {
    setSize({
      id: selectedSize.id,
      width: selectedSize.width,
      height: selectedSize.height,
      custom: false,
    });
    setCustomSizeActive(false);
  };

  const handleQuantitySelection = (selectedQuantity) => {
    setQuantity(selectedQuantity);

    const selectedQuantityData = listQuantities.find(
      (q) => q.quantity === selectedQuantity,
    );
    if (selectedQuantityData) {
      const { price } = selectedQuantityData;
      setPriceActual((price / 100).toFixed(2));
    }
  };

  useEffect(() => {
    if ((size.width && size.height) && (size.width <= 200 && size.height <= 200)) {
      let proportion;
      if (size.custom) {
        proportion = { width: size.width, height: size.height };
      } else {
        proportion = { id: size.id };
      }
      calculateDefaultPricesItem(categoryId, proportion, size.custom)
        .then((pricesCalculated) => {
          setPrices(pricesCalculated.map((elem) => {
            if (elem.priceWithPromo) {
              setPriceWithoutPromo(elem.priceWithoutPromo);
              return (elem.priceWithPromo / 100).toFixed(2);
            }
            return (elem / 100).toFixed(2);
          }));
          if (!quantityCustom) {
            if (pricesCalculated[indexQuantity].priceWithPromo) {
              setPriceActual((pricesCalculated[indexQuantity].priceWithPromo / 100).toFixed(2));
            } else {
              setPriceActual((pricesCalculated[indexQuantity] / 100).toFixed(2));
            }
          }
        })
        .catch(() => {
          setPrices([]);
          setError(true);
          setLoading(false);
        });
    }
  }, [size]);

  useEffect(() => {
    if ((quantityCustom && size.width && size.height) && (size.width <= 200
       || size.height <= 200)) {
      let proportion;
      if (size.custom) {
        proportion = { width: size.width, height: size.height };
      } else {
        proportion = { id: size.id };
      }
      calculateCustomPriceItem(
        categoryId,
        proportion,
        quantityCustom,
        size.custom,
      )
        .then((price) => {
          setPriceActual((price / 100).toFixed(2));
          setPriceCustom((price / 100).toFixed(2));
          setIndexQuantity(null);
        })
        .catch(() => {
          setPrices([]);
          setError(true);
          setLoading(false);
        });
    }
  }, [quantityCustom, size]);

  useEffect(() => {
    if (customSizeActive) {
      setIsPopoverWidthOpen(false);
      setIsPopoverHeigthOpen(false);
      if (size.width && size.height) {
        const result = validateCalculatorInputs(
          categoryId,
          size.width,
          size.height,
        );
        if (result.state === false) {
          setPopoverHeightMessage(result.height);
          setPopoverWidthMessage(result.width);
          setIsPopoverWidthOpen(true);
          setIsPopoverHeigthOpen(true);
        }
      }
    }
  }, [customSizeActive, size.width, size.height]);

  useEffect(() => {
    getSizesForCategory(categoryId)
      .then((sizesData) => {
        setSizes(sizesData);
        if (location.state?.fromHome) {
          setSize({
            id: sizesData[1]?.id || null,
            width: sizesData[1]?.width || null,
            height: sizesData[1]?.height || null,
            custom: size.custom || false,
          });
        } else {
          setSize({
            id: sizesData[0]?.id || null,
            width: sizesData[0]?.width || null,
            height: sizesData[0]?.height || null,
            custom: size.custom || false,
          });
        }
      })
      .catch(() => {
        setSizes([]);
        setError(true);
        setLoading(false);
      });
  }, [categoryId]);

  useEffect(() => {
    const fetchQuantities = async () => {
      try {
        const quantitiesData = await getQuantitiesForCategory(categoryId);
        setListQuantities(quantitiesData);
      } catch (err) {
        setListQuantities([]);
        setError(true);
        setLoading(false);
      }
    };

    fetchQuantities();
  }, [categoryId]);

  const createItem = () => {
    categoryId = parseInt(categoryId, 10);
    if (!size || !quantity || !priceActual || priceActual === '0.00') {
      setError(true);
      return;
    }
    if (customSizeActive === true) {
      if (size.width === null || size.height === null) {
        setError(true);
        return;
      }
      if (isPopoverHeigthOpen || isPopoverHeigthOpen) {
        setError(true);
        return;
      }
    }
    const item = {
      by: 'custom',
      quantity,
      categoryId,
      size,
      price: priceActual,
      product: {
        by: 'custom',
        name: 'productCustom',
        fileId,
      },
      priceWithoutPromotion: priceWithoutPromo,
    };
    if (!sessionToken) {
      history.push('/');
      return;
    }
    setLoading(true);
    createCartItem(sessionToken, item)
      .then((data) => {
        setLoading(false);
        setCart((cartActual) => ({
          ...cartActual,
          items: cart.items.concat([data.cartItem]),
          itemsCount: cart.itemsCount + 1,
        }));
        history.push('/cart');
      })
      .catch(() => {
        setLoading(false);
        history.push('/');
      });
  };

  const setQuantityCustomF = (e) => {
    const value = Number(e.target.value);
    if (parseInt(categoryId, 10) === 28 && value % 2 !== 0) {
      setQuantityErrorMessage('Quantities must be selected in pairs, minimum 2');
    } else {
      setQuantityErrorMessage('');
      setQuantityCustom(value);
      setQuantity(value);
    }
  };
  let radioCheckedQuantity = <div> </div>;

  if (customSizeActive) {
    radioCheckedQuantity = (
      <>
        <p>
          <Popover
            isOpen={isPopoverWidthOpen}
            content={(
              <div className="popover-container-left">
                {popoverWidthMessage}
              </div>
            )}
            positions={['bottom']}
          >
            <label htmlFor="widthCustom">
              <input
                type="number"
                id="widthCustom"
                placeholder="Width"
                className="input-gral inputSelect"
                min="0"
                onChange={(e) => {
                  const value = Number(e.target.value);
                  if (value >= 0) {
                    setSize({
                      ...size,
                      custom: true,
                      width: value,
                    });
                  }
                }}
                onBlur={(e) => {
                  const value = Number(e.target.value);
                  if (value < 0) {
                    setSize({
                      ...size,
                      custom: true,
                      width: 0,
                    });
                  }
                }}
                onKeyPress={(e) => {
                  if (e.key === '-' || e.key === '+') {
                    e.preventDefault();
                  }
                }}
              />
            </label>

          </Popover>
          x
          <Popover
            isOpen={isPopoverHeigthOpen}
            content={(
              <div className="popover-container-rigth">
                {popoverHeightMessage}
              </div>
            )}
            positions={['bottom']}
          >
            <label htmlFor="heightCustom">
              <input
                type="number"
                id="heightCustom"
                placeholder="Height"
                className="input-gral inputSelect"
                min="0"
                onChange={(e) => {
                  const value = Number(e.target.value);
                  if (value >= 0) {
                    setSize({
                      ...size,
                      custom: true,
                      height: value,
                    });
                  }
                }}
                onBlur={(e) => {
                  const value = Number(e.target.value);
                  if (value < 0) {
                    setSize({
                      ...size,
                      custom: true,
                      height: 0,
                    });
                  }
                }}
                onKeyPress={(e) => {
                  if (e.key === '-' || e.key === '+') {
                    e.preventDefault();
                  }
                }}
              />
            </label>

          </Popover>
        </p>
      </>
    );
  }

  let radioCheckedCustomQuantity = <div> </div>;
  if (checkedTwo === true) {
    radioCheckedCustomQuantity = (
      <>
        <p>
          <label htmlFor="quantityCustom">
            <input
              type="number"
              id="quantityCustom"
              placeholder="Quantity"
              className="input-gral inputSelect"
              min="0"
              onChange={setQuantityCustomF}
              value={quantityCustom}
              onBlur={(e) => {
                const value = Number(e.target.value);
                if (value < 0) {
                  setQuantityCustom(0);
                  setQuantity(0);
                }
              }}
              onKeyPress={(e) => {
                if (e.key === '-' || e.key === '+') {
                  e.preventDefault();
                }
              }}
            />
            {quantityErrorMessage && (
            <div className="custom-error-message">
              <p>{quantityErrorMessage}</p>
            </div>
            )}
          </label>
          `
          <span>{`$${priceCustom ?? '0.00'}`}</span>
        </p>
      </>
    );
  }

  const checkedCustomSize = () => {
    setSize({
      width: null,
      height: null,
    });
    setCustomSizeActive(!customSizeActive);
  };

  const handleClickTwo = () => setCheckedTwo((element) => !element);
  let body = (
    <>
      <div className="box-selector">
        <h2>Select a size</h2>
        <ul>
          {sizes
            .sort((a, b) => a.width - b.width)
            .map((value) => {
              let formattedWidth;
              let formattedHeight;

              if (
                parseInt(categoryId, 10) >= 29
                && parseInt(categoryId, 10) <= 33
                && !value.custom
              ) {
                formattedWidth = `${value.width}'`;
                formattedHeight = `${value.height}'`;
              } else {
                formattedWidth = `${value.width}"`;
                formattedHeight = `${value.height}"`;
              }

              return (
                <BoxSizeSelector
                  label={`${formattedWidth} x ${formattedHeight}`}
                  onClick={() => handleSizeSelection(value)}
                  isChecked={size.id === value.id}
                  name={`size-${value.id}`}
                  key={`size-${value.id}`}
                />
              );
            })}

          <label>
            <span className="checkcontainer">
              Custom size
              {radioCheckedQuantity}
              <input
                type="radio"
                name="size"
                onClick={checkedCustomSize}
                checked={customSizeActive}
              />
              <span className="checkmark" />
            </span>
          </label>
        </ul>
      </div>

      <div className="box-selector">
        <h2>Select a quantity</h2>
        <ul>
          {listQuantities.map((quant, index) => (
            <BoxQuantitySelector
              size={quant.quantity}
              name="quantity"
              firstPrice={prices[0] / listQuantities[0].quantity}
              price={`${size !== null ? prices[index] : '0.00'}`}
              checkedTwo
              setChecked={(a) => setCheckedTwo(!a)}
              index={index}
              setValue={(q) => handleQuantitySelection(q)}
              key={`quantity-${quant.quantity}`}
              selectedDefault={
                indexQuantity ? index === indexQuantity : index === 0
              }
              priceWithoutPromo={index === 0
                && prices[0] === '20.00'
                && quant.quantity === 50
                ? priceWithoutPromo
                : null}
            />
          ))}
          <label htmlFor="customQuantity">
            <span className="checkcontainer">
              Custom quantity
              {radioCheckedCustomQuantity}
              <input
                type="radio"
                name="quantity"
                onClick={handleClickTwo}
                id="customQuantity"
              />
              <span className="checkmark" />
            </span>
          </label>
        </ul>
      </div>

      <div className="box-selector">
        <h2>Upload File</h2>
        <UploadFile setFileId={setFileId} />
      </div>
      <button type="button" className="btn-gral" onClick={createItem}>
        continue to cart
      </button>
      {error ? (
        <div className="error-message">
          <p>
            <img src="/assets/error.svg" alt="error" />
            Ooops! Please select a size and quantity
          </p>
        </div>
      ) : null}
    </>
  );

  if (loading) {
    body = (
      <>
        <Loader />
      </>
    );
  }

  return <div className="content-select">{body}</div>;
}
