import React, { useEffect, useState } from 'react';
import './styles.scss';
import { Document, Page, pdfjs } from 'react-pdf';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export default function TableCartView({ item, order }) {
  const itemObject = order ? item.item : item;
  const [productRelated, setProductRelated] = useState(null);

  useEffect(() => {
    const savedProductRelated = JSON.parse(localStorage.getItem(`productRelated_${itemObject.id}`));
    if (savedProductRelated) {
      setProductRelated(savedProductRelated);
    } else {
      setProductRelated(item.fileData);
    }
  }, [itemObject.id, item.fileData]);

  useEffect(() => {
    if (productRelated) {
      localStorage.setItem(`productRelated_${itemObject.id}`, JSON.stringify(productRelated));
    }
  }, [productRelated, itemObject.id]);

  const validarExtension = (path) => {
    if (path.endsWith('.pdf')) {
      return (
        <Document
          file={`${process.env.REACT_APP_API_IMAGE_URL}${path}`}
        >
          <Page pageNumber={1} />
        </Document>
      );
    }
    return <img src={`${process.env.REACT_APP_API_IMAGE_URL}${path}`} alt="product" />;
  };

  return (
    <div className="box-shop">
      <div className="fields description-field">
        {productRelated && productRelated.path ? (
          validarExtension(productRelated.path)
        ) : (
          <img src="/assets/stickerempty.png" alt="empty" />
        )}
        <div className="text-cart">
          <h5>{itemObject.by}</h5>
          {itemObject.by === 'custom' && itemObject.size && (
            <span>{`Size: ${itemObject.size.width}x${itemObject.size.height}`}</span>
          )}
        </div>
      </div>
      <div className="fields quantity-field">
        <span>Quantity</span>
        {itemObject.quantity}
      </div>
    </div>
  );
}
