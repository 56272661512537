import React, { useState, useContext } from 'react';
import { toast } from 'react-toastify';
import { Context } from '../../context';
import { applyCouponToCart, calculateTotalCart2 } from '../../services/cart';
import 'react-toastify/dist/ReactToastify.css';

import './styles.scss';

export default function TotalBox({ shippingPrice }) {
  const { setCart, cart } = useContext(Context);
  const { coupon, data, items } = cart;
  const [couponApplied, setCoupon] = useState(null);
  const [error, setError] = useState(false);
  const [priceSale, setPriceSale] = useState(data.priceWithCoupon || null);
  const priceTotal = calculateTotalCart2(items);
  const priceWithDiscount = coupon ? priceTotal - (priceTotal * coupon.percentage) : null;
  const totalAmount = Number(priceWithDiscount || priceTotal) + Number(shippingPrice);

  const applyCoupon = () => {
    if (coupon) {
      return;
    }
    if (!couponApplied) {
      setError(true);
      return;
    }
    applyCouponToCart(couponApplied, items[0].cartId)
      .then((resp) => {
        toast.success('Coupon successfully applied');
        setPriceSale(resp.price);
        setCart((cartActual) => ({
          ...cartActual,
          coupon: resp.coupon,
        }));
        setError(false);
      })
      .catch(() => {
        setError(true);
        toast.error('Invalid coupon');
      });
  };

  return (
    <>
      <div className="box-general">
        <div className="totalpay-box">
          <h5 className="title-box-form">Coupon</h5>
        </div>
        <div className="general-total-box">
          <input
            type="text"
            defaultValue={coupon ? coupon.code : null}
            disabled={coupon}
            className="input-gral input-coupon"
            placeholder="Do you have a coupon?"
            onChange={(e) => setCoupon(e.target.value)}
          />
          <button className="btn-shipping btn-coupon" type="button" onClick={applyCoupon}>
            Apply
          </button>
          {error}
        </div>
      </div>
      <div className="shippingcont">
        <div className="shippingprice">
          <p className="price-cont">Subtotal</p>
          <p className="price-cont">
            $
            {priceTotal}
          </p>
        </div>
        <div className="shippingprice coupon-discount">
          {(priceWithDiscount || priceSale >= 1) && (
          <>
            <p>Coupon discount:</p>
            <p>
              - $
              { (priceTotal - (priceWithDiscount || priceSale)).toFixed(2) }
            </p>
          </>
          )}
        </div>

        <div className="shippingprice">
          <p className="price-cont">
            Shipping Cost:
          </p>
          <p className="price-cont">
            $
            {shippingPrice}
            {' '}
          </p>
        </div>
        <div className="shippingprice-total">
          <p className="total-amount-text">
            Total Amount
          </p>
          <p className="total-amount-text">
            $
            {' '}
            {totalAmount.toFixed(2)}
          </p>
        </div>
      </div>
    </>
  );
}
