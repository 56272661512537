import React, { useContext } from 'react';
import './styles.scss';
import { Link } from 'react-router-dom';
import TableCart from '../../components/tableCart';
import GridPayment from '../../components/gridPayment';
import { Context } from '../../context';

export default function Cart() {
  const { cart } = useContext(Context);
  let bodyCart = (
    <>
      <div className="title-content">
        <h3 className="title-cat">Checkout</h3>
        <Link href className="btn-gral btn-secondary" to="/">Continue Shopping</Link>
      </div>
      <div className="content-cart-list">
        <div className="content-table">
          <div className="top-table">
            <p>Description</p>
            <p>Quantity</p>
            <p>Total amount</p>
            <p>Delete</p>
          </div>
          {cart.items.map((item) => <TableCart item={item} key={item.id} />)}
        </div>
        <GridPayment />
      </div>
    </>
  );

  if (!cart.sessionToken || cart.items.length === 0) {
    bodyCart = (
      <div className=" loading">
        <div className="empty-cart-content">
          <div>
            <img src="assets/cart-empty.svg" alt="empty cart" />
          </div>
          <div className="txt-empty">
            <h3>Your cart is empty</h3>
            <p>Looks like you haven&apos;t added anything to your cart yet.</p>
            <Link className="btn-gral btn-clean-cart" type="button" to="/">Start Shopping</Link>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="content-cart">
        {bodyCart}
      </div>
    </>
  );
}
