import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import './styles.scss';
import { Document, Page, pdfjs } from 'react-pdf';
import { removeCartItem } from '../../services/cart';
import LoaderMini from '../loaderMini';
import { Context } from '../../context';
import { loadQuantity, loadQuantityLibraryItem } from '../../services/quantity';
import getCategoriesById from '../../services/category';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export default function TableCart({ item }) {
  const history = useHistory();
  const [newQuantity, setQuantity] = useState(item.quantity);
  const [price, setPrice] = useState(item.price);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [quantityError, setQuantityError] = useState(false);
  const [category, setCategory] = useState('Unknown Category');
  const { cart, setCart } = useContext(Context);
  const { coupon } = cart;
  const priceWithDiscount = coupon ? price - (price * coupon.percentage) : null;

  const savedProductRelated = JSON.parse(localStorage.getItem(`productRelated_${item.id}`));
  const productRelated = savedProductRelated || item.product;
  const { priceWithoutPromotion } = item;
  useEffect(() => {
    if (!cart.sessionToken) {
      history.push('/');
    }
  }, [cart.sessionToken, history]);

  useEffect(() => {
    localStorage.setItem(`productRelated_${item.id}`, JSON.stringify(productRelated));
    if (productRelated && productRelated.categoryId) {
      getCategoriesById({ idCategory: productRelated.categoryId })
        .then((data) => {
          if (data.category && data.category.name) {
            setCategory(data.category.name);
          } else {
            setCategory('Unknown Category');
          }
        })
        .catch(() => {
          setCategory('Unknown Category');
        });
    }
  }, [productRelated, item.id]);

  useEffect(() => {
    if (newQuantity <= 0) {
      setQuantityError(true);
    } else {
      setQuantityError(false);
    }
  }, [newQuantity]);

  function updateQuantityForOrderItems(id, quantity) {
    setLoading(true);
    return loadQuantity(id, Number(quantity))
      .then((data) => {
        setQuantity(data.quantity);
        setPrice(data.price);
        const updatedItems = cart.items.filter((itemCart) => itemCart.id !== data.id);
        updatedItems.push(data);
        setCart((cartActual) => ({
          ...cartActual,
          items: updatedItems,
        }));
        setLoading(false);
      })
      .catch(() => {
        setQuantityError(true);
        setLoading(false);
      });
  }

  function updateQuantityForLibraryItems(id, quantity) {
    setLoading(true);
    return loadQuantityLibraryItem(id, Number(quantity))
      .then((data) => {
        setQuantity(data.quantity);
        setPrice(data.price);
        const updatedItems = cart.items.filter((itemCart) => itemCart.id !== data.id);
        updatedItems.push(data);
        setCart((cartActual) => ({
          ...cartActual,
          items: updatedItems.sort((a, b) => a.id - b.id),
        }));
        setLoading(false);
      })
      .catch(() => {
        setQuantityError(true);
        setLoading(false);
      });
  }

  function updateQuantity(id, quantity, type) {
    const updateBy = {
      library: updateQuantityForLibraryItems,
      custom: updateQuantityForOrderItems,
    };
    return updateBy[type](id, quantity);
  }

  const processRemoveCartItem = () => {
    setLoading(true);
    setError(false);
    removeCartItem(cart.sessionToken, item.id)
      .then(() => {
        setLoading(false);
        const actualItems = cart.items.length === 1
          ? [] : cart.items.filter((elem) => elem.id !== item.id);
        setCart((cartActual) => ({
          ...cartActual,
          items: actualItems,
          itemsCount: actualItems.length,
        }));
        localStorage.removeItem(`productRelated_${item.id}`);
      })
      .catch(() => {
        setLoading(false);
        setError(true);
        setTimeout(() => {
          setError(false);
        }, 3000);
      });
  };

  const btnRemove = loading ? <LoaderMini /> : (
    <button type="button" onClick={processRemoveCartItem}>
      <img src="assets/delete.svg" alt="delete" />
    </button>
  );

  const validarExtension = (parametros) => {
    if (parametros.includes('.pdf')) {
      return (
        <Document
          file={`${process.env.REACT_APP_API_IMAGE_URL}${productRelated?.file?.path}`}
          onLoadError={() => setError(true)}
        >
          <Page pageNumber={1} />
        </Document>
      );
    }
    return <img src={`${process.env.REACT_APP_API_IMAGE_URL}${productRelated?.file?.path}`} alt="empty" />;
  };

  const errorMessage = error ? 'Failed to load PDF file' : '';
  const errorQuantityMessage = quantityError ? 'Failed to edit the quantity' : '';

  return (
    <>
      <div className="box-shop">
        <div className="fields description-field">
          { productRelated.file
            ? validarExtension(productRelated.file.path)
            : <img src="/assets/stickerempty.png" alt="empty" />}
          <div className="text-cart">
            <h5>{category !== 'Unknown Category' ? category : `${item.by}`}</h5>
            <span>
              {`Size: ${item.size ? `${item.size.width}x${item.size.height}` : ''}`}
            </span>
          </div>
        </div>
        <div className="fields quantity-field">
          <span>Quantity</span>
          <input
            id={item.id}
            value={newQuantity}
            type="number"
            min="1"
            onChange={(e) => {
              const value = Number(e.target.value);
              if (value > 0) {
                setQuantity(value);
              }
            }}
            onInput={(e) => {
              const value = Number(e.target.value);
              if (value > 0) {
                updateQuantity(item.id, value, item.by);
              } else {
                setQuantity(1);
              }
            }}
          />
        </div>
        <div className="fields total-field">
          <span>Total amount</span>
          {priceWithoutPromotion
          && category === 'Die Cut Stickers'
          && item.size.width === 3
          && item.size.height === 3
          && item.quantity === 50
          && (
            <div className="price-without-promotion">
              <p className="price-without-promo">
                $
                {Number(priceWithoutPromotion / 100).toFixed(2)}
              </p>
            </div>
          )}
          <div className="price">
            $
            {priceWithDiscount?.toFixed(2) || price}
          </div>
        </div>

        <div className="fields delete-field">
          {btnRemove}
        </div>
      </div>
      <div className="box-error">
        {errorMessage}
      </div>
      <div className="box-error">
        {errorQuantityMessage}
      </div>
    </>
  );
}
