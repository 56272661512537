/* eslint-disable no-nested-ternary */
import React from 'react';
import './styles.scss';

export default function BoxQuantitySelector({
  size, name, price, setValue, setChecked, selectedDefault, firstPrice, priceWithoutPromo,
}) {
  const priceStr = `$${price}`;
  const priceWithPromoFormatted = (priceWithoutPromo / 100).toFixed(2);
  const priceWithPromoStr = priceWithPromoFormatted ? `$${priceWithPromoFormatted}` : priceStr;
  const priceByUnit = price / size;
  const average = ((priceByUnit * 100) / firstPrice).toFixed(2);
  const discountPercentage = firstPrice !== 0 && size > 0 ? 100 - Number(average) : 0;
  const averageStr = discountPercentage > 0 ? `${discountPercentage.toFixed(2)}%` : '0.00%';

  return (
    <label htmlFor={size}>
      <div>
        <span aria-hidden="true" className="checkcontainer" onClick={() => setValue(size)}>
          <input type="radio" name={name} id={size} defaultChecked={selectedDefault} onClick={() => setChecked(true)} />
          <div className="container-discount">
            <p>
              {size}
              <span className="price">
                {priceWithoutPromo && <span className="price-without-promo">{priceWithPromoStr}</span>}
                <span>{priceStr}</span>
              </span>
              <div className="span-discount">
                <span>
                  Save
                  {' '}
                  {averageStr}
                </span>
              </div>
            </p>
          </div>
          <span className="checkmark" />
        </span>
      </div>
    </label>
  );
}
