import React from 'react';
import './styles.scss';

export default function GalleryElement({ category }) {
  const defaultImages = [
    '/assets/related1.png',
    '/assets/related2.png',
    '/assets/related3.png',
  ];

  const imagesByCategory = {
    'Die Cut Stickers': [
      '/assets/DieCutStickers-1.jpg',
      '/assets/DieCutStickers-2.jpg',
      '/assets/DieCutStickers-3.jpg',
    ],
    'Circle Stickers': [
      '/assets/CircleStickers-1.jpg',
      '/assets/CircleStickers-2.jpg',
      '/assets/CircleStickers-3.jpg',
    ],
    'Rectangle Stickers': [
      '/assets/RectangleStickers-1.jpg',
      '/assets/RectangleStickers-2.jpg',
      '/assets/RectangleStickers-3.jpg',
    ],
    'Square Stickers': [
      '/assets/SquareStickers-1.jpg',
      '/assets/SquareStickers-2.jpg',
      '/assets/SquareStickers-3.jpg',
    ],
    'Oval Stickers': [
      '/assets/OvalStickers-1.jpg',
      '/assets/OvalStickers-2.jpg',
      '/assets/OvalStickers-3.jpg',
    ],
    'Bumper Stickers': [
      '/assets/BumperStickers-1.jpg',
      '/assets/BumperStickers-2.jpg',
      '/assets/BumperStickers-3.jpg',
    ],
    'Kiss Cut Stickers': [
      '/assets/KissCutStickers-1.jpg',
      '/assets/KissCutStickers-2.jpg',
      '/assets/KissCutStickers-3.jpg',
    ],
    'Rounded Corner Stickers': [
      '/assets/RoundedCornerStickers-1.jpg',
      '/assets/RoundedCornerStickers-2.jpg',
      '/assets/RoundedCornerStickers-3.jpg',
    ],
    'Clear Stickers': [
      '/assets/ClearStickers-1.jpg',
      '/assets/ClearStickers-2.jpg',
      '/assets/ClearStickers-3.jpg',
    ],
    'Vinyl Stickers': [
      '/assets/VinylStickers-1.jpg',
      '/assets/VinylStickers-2.jpg',
      '/assets/VinylStickers-3.jpg',
    ],
    'Static Stickers': [
      '/assets/StaticStickers-1.jpg',
      '/assets/StaticStickers-2.jpg',
      '/assets/StaticStickers-3.jpg',
    ],
    'High Adhesive Stickers': [
      '/assets/HighAdhesiveStickers-1.jpg',
      '/assets/HighAdhesiveStickers-2.jpg',
      '/assets/HighAdhesiveStickers-3.jpg',
    ],
    'Holographic Stickers': [
      '/assets/HolographicStickers-1.jpg',
      '/assets/HolographicStickers-2.jpg',
      '/assets/HolographicStickers-3.jpg',
    ],
    'Transfer Stickers': [
      '/assets/TransferStickers-1.jpg',
      '/assets/TransferStickers-2.jpg',
      '/assets/TransferStickers-3.jpg',
    ],
  };

  const images = imagesByCategory[category?.name] || defaultImages;

  return (
    <div className="galleryCont row">
      {images.map((src, index) => (
        <img
          className="galImg"
          src={src}
          alt={`${`${category?.name || 'Library'} ${index + 1}`}`}
        />
      ))}
    </div>
  );
}
