import React from 'react';
import './styles.scss';
import GalleryElement from '../galleryElement';

export default function TextItemsElement({ category }) {
  return (
    <div className="item-content-list">
      {/* <div className="item-box-text">
        <h4>Shipping</h4>
        <p className="down-text">
          We want to assure you the best quality so a delay of 3-7 laborable days are
          expected.
        </p>
      </div>
      <div className="item-box-text">
        <h4>Proof</h4>
        <p className="down-text">
          After your purchase, when we get the file we&apos;ll check it to make sure that everything
          is in order. You&apos;ll receive an email asking for confirmation, where you can confirm
          or ask for changes. Everything through email.
        </p>
      </div> */}
      <div className="item-box-gallery">
        <GalleryElement category={category} />
      </div>
      <div className="item-box-text">
        <h4>How to order</h4>
        <ul>
          <li><p className="down-text">Choose your preferred size. </p></li>
          <li><p className="down-text">Specify the order quantity.</p></li>
          <li><p className="down-text">Upload your design files or photo.</p></li>
          <li><p className="down-text">Continue to payment.</p></li>
        </ul>
      </div>
      {/* <div className="item-box-text">
        <h4>Durable, waterproof & climate</h4>
        <p className="down-text">
          We use a thick vinyl that protects your stickers from scratches. Also it makes them
          last at least 3 years under the rain & sunlight, and of course, they are waterproof.
        </p>
      </div> */}
    </div>

  );
}
