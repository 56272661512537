import React, { useContext, useState } from 'react';
import { removeAllCartItems } from '../../services/cart';
import { Context } from '../../context';
import LoaderMini from '../loaderMini';

export default function btnCleanCart() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const { cart, setCart } = useContext(Context);

  const cleanCart = () => {
    setLoading(true);
    const cartItems = cart.items;

    removeAllCartItems(cart.sessionToken)
      .then(() => {
        setCart((cartActual) => ({
          ...cartActual,
          itemsCount: 0,
          items: [],
        }));

        cartItems.map((item) => {
          return localStorage.removeItem(`productRelated_${item.id}`);
        });
      })
      .catch(() => {
        setError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const body = loading ? <LoaderMini /> : 'or Clear cart';

  if (error) {
    // body = (
    //   <>
    //     <h3>Error</h3>
    //   </>
    // );
  }

  return (
    <button className="btn-clean-cart" type="button" onClick={cleanCart}>
      {body}
    </button>
  );
}
