import React from 'react';
import './styles.scss';
import { useLocation } from 'react-router-dom';
import LibraryDetail from '../../components/libraryDetail';
import TextItemsElement from '../../components/textItemsElement';
import ItemsBoxMuliple from '../../components/itemsBoxMultiple';

export default function AddProduct() {
  const location = useLocation();
  const locationState = { width: 10, height: 15 };

  const formatPrice = (price) => {
    if (!price) return '';
    return `$${(parseFloat(price)).toFixed(2)}`;
  };

  return (
    <>
      <div className="relative">
        <div className="content-element">
          <div className="select-side">
            <LibraryDetail
              showSizeSelector={false}
              showFileUpload={false}
              location={{ state: locationState }}
            />
          </div>
          <div className="text-element">
            <h5 className="title-cat">{location.state.name}</h5>
            <p className="down-text-cat">
              <img src={`${process.env.REACT_APP_API_IMAGE_URL}${location.state.image}`} alt="empty" />
            </p>
            <p className="down-text-cat">
              PRICE:
              {formatPrice(location.state.price)}
            </p>
            <TextItemsElement />
          </div>
        </div>
      </div>
      <ItemsBoxMuliple />
    </>
  );
}
